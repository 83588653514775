import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { ReactComponent as LoadingIcon } from './loadingIcon.svg';

import * as S from './Loading.styles';

const Loading = ({
  background,
  backgroundFull,
  size,
  ousideContainer,
  position,
  fullHeight,
}) => (
  <S.Container
    ousideContainer={ousideContainer}
    position={position}
    fullHeight={fullHeight}
  >
    <S.Loader
      background={background}
      backgroundFull={backgroundFull}
      size={size}
    >
      <S.Circular size={size}>
        <LoadingIcon />
      </S.Circular>
      <S.Span size={size}>{i18n.t('common-words.loading')}</S.Span>
    </S.Loader>
  </S.Container>
);

Loading.propTypes = {
  background: PropTypes.bool,
  backgroundFull: PropTypes.bool,
  size: PropTypes.string,
  ousideContainer: PropTypes.bool,
  position: PropTypes.string,
  fullHeight: PropTypes.bool,
};

export default Loading;
