import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Selectors } from 'modules/Users/Students/StudentLicenseSlice';
import { Formik } from 'formik';
import i18n from 'i18next';
import decamelize from 'decamelize';

import {
  Row,
  Col,
  Label,
  FormGroup,
  Switch,
  MUIDatePicker,
  SelectAutocomplete,
} from 'modules/Core/Common';
import { FormHeader } from 'modules/Users/Common';
import {
  licenseTypes,
  DEFAULT_PROCTORING_FIELDS,
} from 'modules/Licenses/LicenseUtils';

import LicenseAmount from 'modules/Licenses/LicenseAmount';
import { LicenseRequirements } from 'modules/Licenses/LicenseRequirements';

import * as S from './styles';

import { createStudentLicenseModel } from '../StudentUtils';
import StudentLicenseSchema from '../StudentLicenseSchema';

const StudentLicenseForm = ({
  studentId,
  studentLicense,
  onSubmit,
  btnCloseClick,
}) => {
  const [defaultProctoringFields, setDefaultProctoringFields] = useState([]);
  const [, setCustomTest] = useState([]);
  const { loading } = useSelector(Selectors.studentLicenseLoading);
  const isCustomLicense = studentLicense?.custom_test?.id;
  const formRef = useRef(null);

  const studentLicenseFields = studentLicense
    ? createStudentLicenseModel(studentLicense)
    : createStudentLicenseModel();

  const handleHasAntifraudChange = (
    event,
    setFieldValue,
    handleFormikChange
  ) => {
    DEFAULT_PROCTORING_FIELDS.forEach((proctoringField) => {
      if (proctoringField === 'photoUploadType') {
        setFieldValue(proctoringField, event.target.checked ? 'ID' : '');
      } else {
        const isFieldCheckedByDefault =
          proctoringField === 'roomScan360'
            ? defaultProctoringFields['room_scan_360']
            : defaultProctoringFields[decamelize(proctoringField)];

        setFieldValue(
          proctoringField,
          event.target.checked ? isFieldCheckedByDefault : false
        );
      }
    });
    handleFormikChange(event);
  };

  return (
    <>
      <Formik
        initialValues={studentLicenseFields}
        validationSchema={StudentLicenseSchema}
        onSubmit={(data) => onSubmit(data)}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <S.Form onSubmit={handleSubmit} noValidate>
            <FormHeader
              title={i18n.t(
                'modules.users.students.student-form.form-header.title.student-license'
              )}
              isEdit={!!studentLicense?.id}
              loading={loading}
              disabled={loading}
              btnCloseClick={btnCloseClick}
            />
            <S.FormBody ref={formRef}>
              <Row>
                <Col xs={12}>
                  <S.AntiFraudContainer>
                    <Label>
                      {i18n.t(
                        'modules.licenses.license-forms.license-inputs.has-e-Proctoring'
                      )}
                    </Label>
                    <Switch
                      id="hasAntiFraud"
                      name="hasAntiFraud"
                      active={values.hasAntiFraud}
                      onChange={(event) =>
                        handleHasAntifraudChange(
                          event,
                          setFieldValue,
                          handleChange
                        )
                      }
                    />
                  </S.AntiFraudContainer>
                </Col>

                <Col xs={12} sm={6}>
                  <FormGroup>
                    <Label htmlFor="name">
                      {i18n.t(
                        'modules.licenses.license-forms.license-inputs.expiration-date'
                      )}
                    </Label>

                    <MUIDatePicker
                      inputClick
                      placeholder={i18n.t(
                        'modules.licenses.license-forms.license-inputs.expiration-date'
                      )}
                      textFieldProps={{
                        placeholder: 'Expiration Date',
                      }}
                      date={values.expirationDate}
                      onChange={(newValue) =>
                        newValue && setFieldValue('expirationDate', newValue)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup>
                    <Label htmlFor="licenseType">
                      {i18n.t(
                        'modules.licenses.license-forms.license-inputs.license-type'
                      )}
                    </Label>

                    <SelectAutocomplete
                      id="licenseType"
                      name="licenseType"
                      searchPlaceholder="Search"
                      options={licenseTypes}
                      disabled={isCustomLicense || values.license_id}
                      errors={errors.licenseType}
                      touched={touched.licenseType}
                      containerElement={formRef?.current}
                      value={[values.licenseType]}
                      placeholder="Select license Type"
                      onChangeSelect={(id) => setFieldValue('licenseType', id)}
                    />
                  </FormGroup>
                </Col>
                {values.hasAntiFraud && (
                  <Col xs={12}>
                    <LicenseRequirements
                      data={values}
                      onChange={(param, value) => setFieldValue(param, value)}
                    />
                  </Col>
                )}
              </Row>

              <LicenseAmount
                studentId={studentId}
                license={values}
                handleChangeValue={setFieldValue}
                showLicensesAvailable={false}
                testType={
                  values.testId
                    ? 'base_test'
                    : values.customTestId
                    ? 'custom_test'
                    : ''
                }
                onCustomCategoriesAmountChange={(customCategoriesAmount) => {
                  if (customCategoriesAmount.length > 1) {
                    setFieldValue(
                      'customCategoriesAmount',
                      customCategoriesAmount
                    );
                  } else {
                    setCustomTest((prev) => {
                      const updated = [...prev, customCategoriesAmount[0]];
                      setFieldValue('customCategoriesAmount', updated);
                      return updated;
                    });
                  }
                }}
                onMountProctoringFields={(proctoringFields) =>
                  setDefaultProctoringFields(proctoringFields)
                }
              />
            </S.FormBody>
          </S.Form>
        )}
      </Formik>
    </>
  );
};

StudentLicenseForm.propTypes = {
  studentLicense: PropTypes.object,
  studentId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  btnCloseClick: PropTypes.func.isRequired,
};

export default StudentLicenseForm;
