import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import noScreenImg from 'assets/layout/empty-data/no-image.svg';
import ErrorImgPlaceholder from 'assets/layout/empty-data/Image-Not-Found.svg';
import ExpandImage from 'assets/layout/expand.png';
import CloseImage from 'assets/layout/close.png';
import 'react-image-gallery/styles/scss/image-gallery.scss';

import { GalleryPlaceholder } from './GalleryPlaceholder.component';
import * as S from './Gallery.styles';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

export const Gallery = ({
  photos,
  loading,
  emptyDataPadding,
  noFullScreen,
  galleryRef,
  ...rest
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const moveContainerRef = useRef(null);
  const theme = useTheme();

  const ExpandButtonComponent = (onClick, isFullscreen) => {
    setFullScreen(isFullscreen);

    if (noFullScreen) {
      return null;
    }

    return (
      <S.ExpandButton
        type="button"
        className={`image-gallery-fullscreen-button${
          isFullscreen ? ' active' : ''
        }`}
        onClick={onClick}
      >
        {isFullscreen ? (
          <img
            src={CloseImage}
            alt={i18n.t('commons.galery.no-screen-archives')}
          />
        ) : (
          <img
            src={ExpandImage}
            alt={i18n.t('commons.galery.no-screen-archives')}
          />
        )}
      </S.ExpandButton>
    );
  };

  const CustomRenderRightNav = (onClick, disabled) => {
    return (
      <S.NextButton
        type="button"
        className={`image-gallery-right-nav${fullScreen ? ' active' : ''}`}
        disabled={disabled}
        onClick={onClick}
        aria-label={i18n.t('commons.galery.next-slide')}
      >
        <svg
          width="11"
          height="16"
          viewBox="0 0 11 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.7072 7.00744L9.23077 6.53102H9.15137L2.79901 0.416873C2.24318 -0.138958 1.36973 -0.138958 0.813896 0.416873L0.416873 0.8933C-0.138958 1.44913 -0.138958 2.24318 0.416873 2.79901L5.81638 8.0397L0.416873 13.201C-0.138958 13.7568 -0.138958 14.5509 0.416873 15.1067L0.893301 15.5831C1.44913 16.139 2.32258 16.139 2.87841 15.5831L9.23077 9.46898H9.31017L9.7866 8.99256C10.0248 8.75434 10.1836 8.35732 10.1836 8.0397C10.1836 7.72208 9.94541 7.24566 9.7072 7.00744Z"
            fill={
              fullScreen
                ? theme.config.colors.light
                : theme.config.colors.text_base
            }
          />
        </svg>
      </S.NextButton>
    );
  };

  const renderLeftNavComponent = (onClick, disabled) => {
    return (
      <S.PrevButton
        type="button"
        className={`image-gallery-left-nav${fullScreen ? ' active' : ''}`}
        disabled={disabled}
        onClick={onClick}
        aria-label={i18n.t('commons.galery.previous-slide')}
      >
        <svg
          width="11"
          height="16"
          viewBox="0 0 11 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.2928 8.99256L1.76923 9.46898L1.84863 9.46898L8.20099 15.5831C8.75682 16.139 9.63027 16.139 10.1861 15.5831L10.5831 15.1067C11.139 14.5509 11.139 13.7568 10.5831 13.201L5.18362 7.9603L10.5831 2.79901C11.139 2.24318 11.139 1.44913 10.5831 0.8933L10.1067 0.416873C9.55087 -0.138958 8.67742 -0.138958 8.12159 0.416873L1.76923 6.53102L1.68983 6.53102L1.2134 7.00744C0.975186 7.24566 0.816377 7.64268 0.816377 7.9603C0.816377 8.27791 1.05459 8.75434 1.2928 8.99256Z"
            fill={
              fullScreen
                ? theme.config.colors.light
                : theme.config.colors.text_base
            }
          />
        </svg>
      </S.PrevButton>
    );
  };

  if (loading) return <GalleryPlaceholder />;
  if (!photos?.length) {
    return (
      <S.NoScreenArchive emptyDataPadding={emptyDataPadding}>
        <img
          src={noScreenImg}
          alt={i18n.t('commons.galery.no-screen-archives')}
        />
      </S.NoScreenArchive>
    );
  }

  const handleMoveButton = (e) => {
    const moveContainerWidth = moveContainerRef.current.offsetWidth;
    const mouseX = e.clientX - moveContainerRef.current.offsetLeft;
    const newIndex = Math.floor((mouseX / moveContainerWidth) * photos.length);
    setCurrentIndex(Math.min(Math.max(newIndex, 0), photos.length - 1));
  };

  const handleMoveClick = (e) => {
    e.preventDefault();

    const handleMouseMoveDuringDrag = (moveEvent) => {
      handleMoveButton(moveEvent);
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMoveDuringDrag);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMoveDuringDrag);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <S.Container data-testid="gallery">
      <ImageGallery
        {...rest}
        ref={galleryRef}
        items={photos}
        startIndex={currentIndex}
        onThumbnailClick={(_, index) => {
          setCurrentIndex(index);
        }}
        renderFullscreenButton={ExpandButtonComponent}
        renderRightNav={CustomRenderRightNav}
        renderLeftNav={renderLeftNavComponent}
        onErrorImageURL={ErrorImgPlaceholder}
      />
      <S.MoveContainer
        className={`${fullScreen ? 'active' : ''}`}
        ref={moveContainerRef}
      >
        <S.MoveEl
          style={{
            left: `calc(max(0%, ${
              (currentIndex / (photos.length - 1)) * 100
            }% - 32px))`,
          }}
          onMouseDown={handleMoveClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="18 8 22 12 18 16" />
            <polyline points="6 8 2 12 6 16" />
            <line x1="2" y1="12" x2="22" y2="12" />
          </svg>
        </S.MoveEl>
      </S.MoveContainer>
    </S.Container>
  );
};

Gallery.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      original: PropTypes.string,
      thumbnail: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  navFullScreen: PropTypes.bool,
  emptyDataPadding: PropTypes.string,
  noFullScreen: PropTypes.bool,
  galleryRef: PropTypes.any,
};
