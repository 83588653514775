import { useEffect, useRef } from 'react';
import { useInstitutionFiltersGroups } from './useInstitutionsFiltersGroups';
import { useInstitutionFiltersUnits } from './useInstitutionsFiltersUnits';
import { useInstitutionFiltersSchools } from './useInstitutionsFiltersSchools';

export const useInstitutionsFilters = ({
  submissionFilters,
  updateSubmissionFilter,
}) => {
  const isInitialLoad = useRef(true);

  const {
    groups,
    loading: groupsLoading,
    error: groupsError,
    fetchGroupsForSelect,
  } = useInstitutionFiltersGroups();

  const {
    units,
    loading: unitsLoading,
    error: unitsError,
    fetchUnitsByGroup,
  } = useInstitutionFiltersUnits();

  const {
    schools,
    loading: schoolsLoading,
    error: schoolsError,
    fetchSchoolsByUnit,
  } = useInstitutionFiltersSchools();

  // Initial load - fetch groups only once
  useEffect(() => {
    fetchGroupsForSelect();
  }, []);

  // Handle group_id changes
  useEffect(() => {
    async function handleGroupChange() {
      if (submissionFilters.group_id) {
        // If it's not the initial load, reset downstream filters
        if (!isInitialLoad.current) {
          updateSubmissionFilter({ filterName: 'unit_id', id: null });
          updateSubmissionFilter({ filterName: 'school_id', id: null });
        }
        await fetchUnitsByGroup(submissionFilters.group_id);
      }
    }
    handleGroupChange();
  }, [submissionFilters.group_id]);

  // Handle unit_id changes
  useEffect(() => {
    async function handleUnitChange() {
      if (submissionFilters.unit_id) {
        // If it's not the initial load, reset school filter
        if (!isInitialLoad.current) {
          updateSubmissionFilter({ filterName: 'school_id', id: null });
        }
        await fetchSchoolsByUnit(submissionFilters.unit_id);
      }
    }
    handleUnitChange();
  }, [submissionFilters.unit_id]);

  // Set isInitialLoad to false after all initial data is loaded
  useEffect(() => {
    if (
      submissionFilters.group_id &&
      submissionFilters.unit_id &&
      submissionFilters.school_id &&
      groups.length > 0 &&
      units.length > 0 &&
      schools.length > 0
    ) {
      isInitialLoad.current = false;
    }
  }, [
    submissionFilters.group_id,
    submissionFilters.unit_id,
    submissionFilters.school_id,
    groups.length,
    units.length,
    schools.length,
  ]);

  return {
    // Groups
    groups,
    groupsLoading,
    groupsError,

    units: submissionFilters.group_id ? units : [],
    unitsLoading,
    unitsError,

    schools: submissionFilters.unit_id ? schools : [],
    schoolsLoading,
    schoolsError,
  };
};
