import PropTypes from 'prop-types';
import i18n from 'i18next';
import { capitalize } from 'lodash';
import { ArrowRightIcon } from '@edusynch/edusynch-svg-icons';

import * as S from './UserInstitutionsInfo.styles';

const LABEL_PREFIX = 'common-words';

export const UserInstitutionsInfo = ({ student }) => {
  if (!student?.group) return null;

  return (
    <S.FlexList>
      {student?.group?.name && (
        <S.ArrowContainer>
          <S.Span>{i18n.t(`${LABEL_PREFIX}.group`)}:</S.Span>
          <S.Title>{capitalize(student?.group?.name)}</S.Title>
        </S.ArrowContainer>
      )}
      {student?.unit?.name && (
        <S.ArrowContainer>
          <ArrowRightIcon />
          <S.Span>{i18n.t(`${LABEL_PREFIX}.unit`)}:</S.Span>
          <S.Title>{capitalize(student?.unit?.name)}</S.Title>
        </S.ArrowContainer>
      )}
      {student?.school?.name && (
        <S.ArrowContainer>
          <ArrowRightIcon />
          <S.Span>{i18n.t(`${LABEL_PREFIX}.school`)}:</S.Span>
          <S.Title>{capitalize(student?.school?.name)}</S.Title>
        </S.ArrowContainer>
      )}
      {student?.student_class?.name && (
        <S.ArrowContainer>
          <ArrowRightIcon />
          <S.Span>{i18n.t(`${LABEL_PREFIX}.class`)}:</S.Span>
          <S.Title>{capitalize(student?.student_class?.name)}</S.Title>
        </S.ArrowContainer>
      )}
    </S.FlexList>
  );
};

UserInstitutionsInfo.propTypes = {
  student: PropTypes.shape({
    group: PropTypes.object,
    unit: PropTypes.object,
    school: PropTypes.object,
    student_class: PropTypes.object,
  }),
};
