import { useDispatch, useSelector } from 'react-redux';
import { Async, Selectors } from '../InstitutionsFilters/InstitutionsSlice';

export const useInstitutionFiltersSchools = () => {
  const dispatch = useDispatch();
  const { data: schools, loading, error } = useSelector(Selectors.schools);

  const fetchSchoolsByUnit = (unitId) => {
    if (unitId) {
      dispatch(Async.fetchSchoolsListByUnitId({ unitId }));
    }
  };

  return {
    schools: schools || [],
    loading,
    error,
    fetchSchoolsByUnit,
  };
};
