import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { OutlineTestIcon } from '@edusynch/edusynch-svg-icons';

const BaseList = lazy(() =>
  import('modules/Tests/TestsBase/TestsBaseList/TestsBaseList.container')
);

const CustomList = lazy(() =>
  import('modules/Tests/TestsCustom/TestsCustomList/TestsCustomList.container')
);

const LevelLabelsList = lazy(() =>
  import('modules/Tests/LevelLabels/LevelLabelsList/LevelLabelsList.container')
);

const TestsMenu = menuBuilder([
  {
    Icon: OutlineTestIcon,
    name: 'Tests',
  },
]).addSubmenu([
  {
    name: 'Base Tests',
    path: '/base-tests/base-list',
    exact: true,
    component: BaseList,
    ability: 'content_tests',
  },
  {
    name: 'Custom Tests',
    path: '/custom-tests/custom-list',
    exact: true,
    component: CustomList,
    ability: 'content_tests',
  },
  {
    name: 'Level Labels',
    path: '/level-labels',
    exact: true,
    component: LevelLabelsList,
    ability: 'content_tests',
  },
]);

export default TestsMenu;
