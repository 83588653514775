import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18next';

import { useQuery } from 'modules/Core/Hooks';
import {
  getUTCCustomStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';

import { useURLSearchQuery } from 'modules/Core/Hooks';

import { Toast } from 'modules/Core/Common';
import { defaultErrorToast } from 'modules/Utils';

import {
  Selectors,
  Actions,
  Async,
} from 'modules/Submissions/SubmissionsSlice';

export const useSubmissions = ({ testType }) => {
  const state = useSelector(Selectors.fetchListData);
  const dispatch = useDispatch();
  const location = useLocation();
  const { queryObject, changeParams } = useURLSearchQuery();
  const searchQuery = useQuery();
  const testQuery = Number(searchQuery.get('test_id'));
  const groupQuery = Number(searchQuery.get('group_id'));
  const unitQuery = Number(searchQuery.get('unit_id'));
  const schoolQuery = Number(searchQuery.get('school_id'));
  const certificationQuery = searchQuery.get('certificate_status');

  useEffect(() => {
    let params = {
      search: queryObject?.search || '',
      page: queryObject?.page || 1,
      paginates_per: queryObject?.paginates_per || 10,
      evaluator_id: queryObject?.evaluator_id,
      status_exercise: queryObject?.status_exercise,
      test_id: queryObject?.test_id,
      test_tag: queryObject?.test_tag,
      group_id: queryObject?.group_id,
      unit_id: queryObject?.unit_id,
      school_id: queryObject?.school_id,
      certificate_status: queryObject?.certificate_status,
    };

    if (['invalid', 'practice'].includes(testType)) {
      params = {
        ...params,
        start_date: queryObject?.start_date || getUTCCustomStartDate(7),
        end_date: queryObject?.end_date || getUTCDefaultEndDate(),
      };
    } else {
      const hasDateFilterOnURLQuery =
        !!queryObject?.start_submission_date ||
        !!queryObject?.start_evaluated_date ||
        !!queryObject?.start_schedule_date;

      params = {
        ...params,
        start_evaluated_date: queryObject?.start_evaluated_date,
        end_evaluated_date: queryObject?.end_evaluated_date,
        start_schedule_date: queryObject?.start_schedule_date,
        end_schedule_date: queryObject?.end_schedule_date,
        start_submission_date: !hasDateFilterOnURLQuery
          ? getUTCCustomStartDate(7)
          : queryObject?.start_submission_date,
        end_submission_date: !hasDateFilterOnURLQuery
          ? getUTCDefaultEndDate()
          : queryObject?.end_submission_date,
      };
    }

    changeParams(params);

    dispatch(Actions.changeSubmissionsFilters(params));

    return () => dispatch(Actions.cancelRequests());
  }, []);

  useEffect(() => {
    fetchSubmissionsList({ testType });
  }, [
    state.data.page,
    state.data.search,
    state.data.esEvaluator,
    state.data.evaluatorId,
    state.data.statusExercise,
    state.data.testId,
    state.data.groupId,
    state.data.unitId,
    state.data.schoolId,
    state.data.testTag,
    state.data.certificateStatus,
    state.data.perPage,
    state.data.startDate,
    state.data.endDate,
    state.data.startEvaluatedDate,
    state.data.endEvaluatedDate,
    state.data.startScheduleDate,
    state.data.endScheduleDate,
    state.data.startSubmissionDate,
    state.data.endSubmissionDate,
    state.data.sort.sortType,
    state.data.sort.sortBy,
  ]);

  useEffect(() => {
    testQuery &&
      dispatch(
        Actions.changeExportFilter({
          param: 'test',
          value: testQuery,
        })
      );
  }, [testQuery]);

  useEffect(() => {
    groupQuery &&
      dispatch(
        Actions.changeExportFilter({
          param: 'group',
          value: groupQuery,
        })
      );
  }, [groupQuery]);

  useEffect(() => {
    unitQuery &&
      dispatch(
        Actions.changeExportFilter({
          param: 'unit',
          value: unitQuery,
        })
      );
  }, [unitQuery]);

  useEffect(() => {
    schoolQuery &&
      dispatch(
        Actions.changeExportFilter({
          param: 'school',
          value: schoolQuery,
        })
      );
  }, [schoolQuery]);

  useEffect(() => {
    certificationQuery &&
      dispatch(
        Actions.changeExportFilter({
          param: 'certificate_status',
          value: certificationQuery,
        })
      );
  }, [certificationQuery]);

  useEffect(() => {
    return () => dispatch(Actions.cleanState());
  }, [location.pathname]);

  const clearError = () => dispatch(Actions.clearError());

  const handleDelete = (id) => {
    const action = Async.deleteSubmission({
      testType,
      id,
      onSuccess: () => {
        Toast(
          i18n.t('success-messages.successfully-toast.deleted-test-submission'),
          'success'
        );
        dispatch(Async.fetchSubmissionsList({ testType }));
      },
      onError: (e) =>
        defaultErrorToast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.delete-test-submission'
          )
        )(e),
    });

    dispatch(action);
  };

  const handleSort = (sortBy) => dispatch(Actions.changeSort(sortBy));

  const fetchSubmissionsList = (params) =>
    dispatch(Async.fetchSubmissionsList(params));

  const onNextPage = (page) => {
    changeParams({ page });
    dispatch(Actions.changeSubmissionsPage(page));
  };

  const onChangePerPage = (value) => {
    changeParams({ paginates_per: value, page: 1 });
    dispatch(Actions.changeSubmissionsPerPage(value));
  };

  const handleChangeExportFilter = (param, value) => {
    dispatch(Actions.changeExportFilter({ param, value }));
  };

  const handleSubmitExport = (callback) => {
    const onSuccess = (response) => callback('success', response);
    const onError = () => callback('error');

    const action =
      testType !== 'custom'
        ? Async.fetchExportSubmitBase({ onSuccess, onError })
        : Async.fetchExportSubmitCustom({ onSuccess, onError });

    dispatch(action);
  };

  const onChangeSearch = (filters) => {
    changeParams({ search: filters.search, page: 1 });
    dispatch(Actions.changeSubmissionsSearch(filters));
  };

  const onChangeEsEvaluator = (value) => {
    changeParams({ es_evaluator: value, page: 1 });
    dispatch(Actions.changeSubmissionsEsEvaluator(value));
  };

  const changeSubmissionsFilters = (filters) => {
    let parseFilters = { ...filters };

    let params = {
      test_id: filters?.test_id,
      group_id: filters?.group_id,
      unit_id: filters?.unit_id,
      school_id: filters?.school_id,
      test_tag: filters?.test_tag,
      evaluator_id: filters?.evaluator_id,
      certificate_status: filters?.certificate_status,
      status_exercise: filters?.status_exercise,
      search: filters?.search,
      page: 1,
    };

    if (['invalid', 'practice'].includes(testType)) {
      parseFilters = {
        ...parseFilters,
        start_date: filters?.start_date || getUTCCustomStartDate(7),
        end_date: filters?.end_date || getUTCDefaultEndDate(),
      };

      params = {
        ...params,
        start_date: filters?.start_date || getUTCCustomStartDate(7),
        end_date: filters?.end_date || getUTCDefaultEndDate(),
      };
    } else {
      params = {
        ...params,
        start_evaluated_date: filters?.start_evaluated_date,
        end_evaluated_date: filters?.end_evaluated_date,
        start_schedule_date: filters?.start_schedule_date,
        end_schedule_date: filters?.end_schedule_date,
        certificate_status: filters?.certificate_status,
        start_submission_date:
          filters?.start_submission_date || getUTCCustomStartDate(7),
        end_submission_date:
          filters?.end_submission_date || getUTCDefaultEndDate(),
      };
    }

    changeParams(params);
    dispatch(Actions.changeSubmissionsFilters(parseFilters));
  };

  const onChangeCertificateAvailable = (exerciseId) => {
    const action = Async.changeCertificateAvailable({
      exerciseId,
      onSuccess: () => {
        dispatch(Async.fetchSubmissionsList({ testType }));
      },
    });

    dispatch(action);
  };

  return {
    state,
    fetchSubmissionsList,
    onNextPage,
    onChangePerPage,
    clearError,
    handleDelete,
    handleSort,
    handleChangeExportFilter,
    handleSubmitExport,
    onChangeSearch,
    onChangeEsEvaluator,
    changeSubmissionsFilters,
    onChangeCertificateAvailable,
    isChangingCertificateAvailable: state.isChangingCertificateAvailable,
  };
};
