import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Selectors,
  Async,
  Actions,
} from 'modules/Dashboard/Widgets/WidgetsSlice';

import { ACTIVE_USERS } from 'modules/Dashboard/Widgets/Common/WidgetByType/Widgets.types';

export const useActiveUsers = () => {
  const { activeUsers } = useSelector(Selectors.fetchListData);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(Actions.cancelRequests());
  }, []);

  const fetchActiveUsers = (intervalOption = '7') => {
    dispatch(
      Async.fetchWidget({
        widget_type: ACTIVE_USERS,
        filters: {
          days_type: +intervalOption,
        },
      })
    );
  };

  return {
    fetchActiveUsers,
    activeUsers,
  };
};
