const createStudentModel = (student = {}) => ({
  id: student.id || '',
  name: student.name || '',
  nickname: student.nickname || '',
  email: student.email || '',
  password: '',
  passwordConfirmation: '',
  enrollmentId: student.enrollment_id || student.enroll || '', // returns enroll
  origin: student.origin || '',
  language: student.language || '',
  groupId: student.group?.id || '',
  groupLicenseId: student.group_license?.id || '',
  unitId: student.unit?.id || '',
  unitLicenseId: student.unit_license?.id || '',
  schoolId: student.school?.id || '',
  schoolLicenseId: student.school_license?.id || '',
  classId: student.student_class?.id || '',
  classLicenseId: student.class_license?.id || '',
  whitelabelId: student.whitelabel_id || '',
  externalApplicationId: student.external_application_id || '',
  avatar: student.avatar || '',
  passportImage: student.passport_image || '',
  photo: student.photo || '',
  toeflEnabled: student.toefl_enabled || false,
  toeflHide: student.toefl_hide || false,
  ieltsAcademicEnabled: student.ielts_academic_enabled || false,
  ieltsAcademicHide: student.ielts_academic_hide || false,
  toeicLrEnabled: student.toeic_lr_enabled || false,
  toeicLrHide: student.toeic_lr_hide || false,
  a2FlyersEnabled: student.a2_flyers_enabled || false,
  a2FlyersHide: student.a2_flyers_hide || false,
  satEnabled: student.sat_enabled || false,
  satHide: student.sat_hide || false,
  cefrEnabled: student.cefr_enabled || false,
  cefrHide: student.cefr_hide || false,
  mcerEnabled: student.mcer_enabled || false,
  mcerHide: student.mcer_hide || false,
  cefrRlEnabled: student.cefr_rl_enabled || false,
  placementTestEnabled: student.placement_test_enabled || false,
  placementTestHide: student.placement_test_hide || false,
  cefrRlHide: student.cefr_rl_hide || false,
  customTests: student.custom_tests || [],
  hideAnalysis: student.hide_analysis || false,
  university_student_id: student.university_student_id || '',
  passport_number: student.passport_number || '',
  nationality: student.nationality || '',
  linguaskillGeneralEnabled: student.linguaskill_general_enabled || false,
  linguaskillGeneralHide: student.linguaskill_general_hide || false,
  linguaskillBusinessEnabled: student.linguaskill_business_enabled || false,
  linguaskillBusinessHide: student.linguaskill_business_hide || false,
});

const createStudentRequestModel = (student = {}) => {
  const studentRequestModel = {
    id: student.id,
    name: student.name,
    nickname: student.nickname,
    email: student.email,
    enrollment_id: student.enrollmentId,
    origin: student.origin || '',
    language: student.language || '',
    group_id: student.groupId,
    group_license_id: student.groupLicenseId,
    unit_id: student.unitId,
    unit_license_id: student.unitLicenseId,
    school_id: student.schoolId,
    school_license_id: student.schoolLicenseId,
    class_id: student.classId,
    class_license_id: student.classLicenseId,
    whitelabel_id: student.whitelabelId,
    external_application_id: student.externalApplicationId,
    toefl_enabled: student.toeflEnabled,
    toefl_hide: student.toeflHide,
    ielts_academic_enabled: student.ieltsAcademicEnabled,
    ielts_academic_hide: student.ieltsAcademicHide,
    toeic_lr_enabled: student.toeicLrEnabled,
    toeic_lr_hide: student.toeicLrHide,
    a2_flyers_enabled: student.a2FlyersEnabled,
    a2_flyers_hide: student.a2FlyersHide,
    sat_enabled: student.satEnabled,
    sat_hide: student.satHide,
    cefr_enabled: student.cefrEnabled,
    cefr_hide: student.cefrHide,
    mcer_enabled: student.mcerEnabled,
    mcer_hide: student.mcerHide,
    cefr_rl_enabled: student.cefrRlEnabled,
    placement_test_enabled: student.placementTestEnabled,
    placement_test_hide: student.placementTestHide,
    cefr_rl_hide: student.cefrRlHide,
    custom_tests: student.customTests,
    hide_analysis: student.hideAnalysis,
    university_student_id: student.university_student_id,
    passport_number: student.passport_number,
    nationality: student.nationality,
    special_needs_description: student.special_needs_description,
    linguaskill_general_enabled: student.linguaskillGeneralEnabled,
    linguaskill_general_hide: student.linguaskillGeneralHide,
    linguaskill_business_enabled: student.linguaskillBusinessEnabled,
    linguaskill_business_hide: student.linguaskillBusinessHide,
  };

  if (student.avatar instanceof File)
    studentRequestModel.avatar = student.avatar;
  if (student.passportImage instanceof File)
    studentRequestModel.passport_image = student.passportImage;
  if (student.photo instanceof File) studentRequestModel.photo = student.photo;
  if (student.password && student.passwordConfirmation) {
    studentRequestModel.password = student.password;
    studentRequestModel.password_confirmation = student.passwordConfirmation;
  }

  studentRequestModel.custom_tests = JSON.stringify(
    student.customTests.map((customTest) => ({
      id: customTest.custom_test_id,
      enabled: true,
    }))
  );

  return studentRequestModel;
};

const createStudentLicenseModel = (license = {}) => ({
  id: license.id || null,
  hasAntiFraud: license.enable_apply_photos || false,
  cefrAmount: license.cefr_amount || 0,
  mcerAmount: license.mcer_amount || 0,
  customTestName: license.custom_test?.name || '',
  expirationDate: license.expiration_date || '',
  fullTestAmount: license.full_test_amount || 0,
  licenseType: license.license_type || 'EDUSYNCH',
  licenseId: license.license_id || null,
  listeningAmount: license.listening_amount || 0,
  listeningPracticeAmount: license.listening_practice_amount || 0,
  testName: license.test?.name || '',
  readingAmount: license.reading_amount || 0,
  readingPracticeAmount: license.reading_practice_amount || 0,
  mathAmount: license.math_amount || 0,
  mathPracticeAmount: license.math_practice_amount || 0,
  readingWritingAmount: license.reading_writing_amount || 0,
  readingWritingPracticeAmount: license.reading_writing_practice_amount || 0,
  speakingAmount: license.speaking_amount || 0,
  speakingPracticeAmount: license.speaking_practice_amount || 0,
  studentId: license.student_id || '',
  writingAmount: license.writing_amount || 0,
  writingPracticeAmount: license.writing_practice_amount || 0,
  testId: license.test?.id || null,
  customTestId: license.custom_test?.id || null,
  customCategoriesAmount: license.custom_categories_amount || [],
  photoStudent: license.photo_student || false,
  photoUploadId: license.photo_upload_id || false,
  photoUploadType: license.photo_upload_type || false,
  takePictureNextId: license.take_picture_next_id || false,
  screenShare: license.screen_share || false,
  roomScan360: license.room_scan_360 || false,
  roomScanRandom: license.room_scan_random || false,
  enableUserScreenRecording: license.enable_user_screen_recording || false,
  enableUserVideoRecording: license.enable_user_video_recording || false,
  enableUserAudioRecording: license.enable_user_audio_recording || false,
});

const createStudentLicenseRequestModel = (studentLicense = {}) => {
  const studentLicenseObj = {
    id: studentLicense.id,
    student_id: studentLicense.studentId,
    enable_apply_photos: studentLicense.hasAntiFraud,
    cefr_amount: studentLicense.cefrAmount,
    mcer_amount: studentLicense.mcerAmount,
    expiration_date: studentLicense.expirationDate,
    full_test_amount: studentLicense.fullTestAmount,
    license_id: studentLicense.licenseId,
    listening_amount: studentLicense.listeningAmount,
    listening_practice_amount: studentLicense.listeningPracticeAmount,
    reading_amount: studentLicense.readingAmount,
    reading_practice_amount: studentLicense.readingPracticeAmount,
    math_amount: studentLicense.mathAmount,
    math_practice_amount: studentLicense.mathPracticeAmount,
    reading_writing_amount: studentLicense.readingWritingAmount,
    reading_writing_practice_amount:
      studentLicense.readingWritingPracticeAmount,
    speaking_amount: studentLicense.speakingAmount,
    speaking_practice_amount: studentLicense.speakingPracticeAmount,
    writing_amount: studentLicense.writingAmount,
    writing_practice_amount: studentLicense.writingPracticeAmount,
    test_id: studentLicense.testId,
    custom_test_id: studentLicense.customTestId,
    custom_categories_amount: studentLicense.customCategoriesAmount,
    photo_student: studentLicense.photoStudent,
    photo_upload_id: studentLicense.photoUploadId,
    photo_upload_type: studentLicense.photoUploadType,
    take_picture_next_id: studentLicense.takePictureNextId,
    screen_share: studentLicense.screenShare,
    room_scan_360: studentLicense.roomScan360,
    room_scan_random: studentLicense.roomScanRandom,
    license_type: studentLicense.licenseType,
    enable_user_screen_recording: studentLicense.enableUserScreenRecording,
    enable_user_video_recording: studentLicense.enableUserVideoRecording,
    enable_user_audio_recording: studentLicense.enableUserAudioRecording,
  };

  if (studentLicenseObj?.custom_test_id) {
    delete studentLicenseObj.test_id;
  } else {
    delete studentLicenseObj.custom_test_id;
  }

  return studentLicenseObj;
};

export {
  createStudentModel,
  createStudentRequestModel,
  createStudentLicenseModel,
  createStudentLicenseRequestModel,
};
