import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Actions } from 'modules/Submissions/SubmissionsSlice';
import { useURLSearchQuery } from 'modules/Core/Hooks';

const currentDate = new Date();
const currentEndDate = currentDate.toISOString().substring(0, 10);
currentDate.setMonth(currentDate.getMonth() - 1);
const currentStartDate = currentDate.toISOString().substring(0, 10);

const INITIAL_FILTERS_STATE = {
  test_id: '',
  test_tag: '',
  evaluator_id: '',
  es_evaluator: false,
  start_date: currentStartDate,
  end_date: currentEndDate,
  certificate_status: 'ALL',
};

export const useSubmissionsFilterWidget = () => {
  const [selectedFilters, setSelectedFilters] = useState(INITIAL_FILTERS_STATE);
  const dispatch = useDispatch();
  const { queryObject, changeParams } = useURLSearchQuery();

  useEffect(() => {
    setSelectedFilters({
      ...selectedFilters,
      test_id: queryObject?.test_id || 'all',
      test_tag: queryObject.test_tag || '',
      es_evaluator: !!queryObject?.es_evaluator,
      evaluator_id: queryObject?.evaluator_id || 'all',
      certificate_status: queryObject?.certificate_status,
      start_date: queryObject?.start_date || currentStartDate,
      end_date: queryObject?.end_date || currentEndDate,
    });
  }, []);

  const handleSaveFilter = () => {
    changeParams({
      ...selectedFilters,
      page: 1,
    });

    dispatch(
      Actions.changeSubmissionsFilters({
        ...selectedFilters,
      })
    );
  };

  const handleUpdateFilter = (filter) =>
    setSelectedFilters({ ...selectedFilters, ...filter });

  return {
    selectedFilters,
    handleSaveFilter,
    handleUpdateFilter,
  };
};
