import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import {
  LEVEL_LABELS_URL,
  LEVEL_LABELS_LIST_URL,
  LEVEL_LABELS_GET_MARKS_BY_TEST_URL,
  WHITELABELS_URL,
  BASE_TESTS_URL,
  CUSTOM_TESTS_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultErrorToast } from 'modules/Utils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  tests: {
    loading: false,
    data: [],
  },
  whitelabels: {
    loading: false,
    data: [],
  },
  levelLabelsFields: {
    loading: false,
    data: [],
  },
  levelLabelsEdit: {
    loading: false,
    data: null,
  },
};

const levelLabelsSlice = createSlice({
  name: 'levelLabels',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changePage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeLevelLabelsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    clearLevelLabelsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    clearLevelLabelsEdit: (state) => {
      state.levelLabelsEdit.loading = false;
      state.levelLabelsEdit.data = null;
      state.levelLabelsFields.loading = false;
      state.levelLabelsFields.data = null;
    },
    requestLevelLabels: (state) => {
      state.loading = true;
      state.error = null;
    },
    receiveLevelLabelsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        levelLabels: action.payload.whitelabel_level_tests,
        total: action.payload.total_items,
      };
    },
    receiveLevelLabelsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    requestWhitelabels: (state) => {
      state.whitelabels.loading = true;
      state.whitelabels.error = null;
    },
    receiveWhitelabelsList: (state, action) => {
      state.whitelabels.loading = false;
      state.whitelabels.data = action.payload.content.whitelabels;
    },
    receiveWhitelabelsError: (state, action) => {
      state.whitelabels.loading = false;
      state.whitelabels.error = action.payload;
    },
    requestTests: (state) => {
      state.tests.loading = true;
      state.tests.error = null;
    },
    receiveTestsList: (state, action) => {
      state.tests.loading = false;
      const baseTests = action.payload?.tests || [];
      const customTests = action.payload?.customTests || [];
      const allTests = [
        ...baseTests.map((test) => ({
          id: test.tag,
          value: test.id,
          name: test.name,
          tag: test.tag,
        })),
        ...customTests.map((test) => ({
          id: test.tag,
          value: test.id,
          name: test.name,
          tag: test.tag,
          isCustomTest: true,
        })),
      ];
      state.tests.data = allTests;
    },
    receiveTestsError: (state, action) => {
      state.tests.loading = false;
      state.tests.error = action.payload;
    },
    requestLevelLabelsFields: (state) => {
      state.levelLabelsFields.loading = true;
      state.levelLabelsFields.error = null;
    },
    receiveLevelLabelsFieldsList: (state, action) => {
      state.levelLabelsFields.loading = false;
      state.levelLabelsFields.data =
        action.payload?.marks?.map((mark) => ({
          mark: mark || '-',
          label: '',
          color: '#FFFFFF',
        })) || [];
    },
    receiveLevelLabelsFieldsError: (state, action) => {
      state.levelLabelsFields.loading = false;
      state.levelLabelsFields.error = action.payload;
    },
    requestLevelLabelsEdit: (state) => {
      state.levelLabelsEdit.loading = true;
    },
    receiveLevelLabelsEditSuccess: (state, action) => {
      state.levelLabelsEdit.loading = false;
      state.levelLabelsEdit.data = action.payload;
    },
    receiveLevelLabelsEditError: (state) => {
      state.levelLabelsEdit.loading = false;
    },
    clearLevelLabelsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
  },
});

const Actions = levelLabelsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.levelLabels,
  levelLabelsLoading: ({ levelLabels: { loading } }) => ({ loading }),
};

const Async = {
  fetchLevelLabelsList: () => async (dispatch, getState) => {
    const {
      levelLabels: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestLevelLabels());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: LEVEL_LABELS_LIST_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveLevelLabelsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveLevelLabelsError(e.message);
      }
    }

    action && dispatch(action);
  },

  getLevelLabelsById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestLevelLabelsEdit());

      try {
        const response = await request({
          method: 'GET',
          url: `${LEVEL_LABELS_URL}/${id}`,
        });

        action = Actions.receiveLevelLabelsEditSuccess(response.data.content);
        onSuccess?.(response.data.content);
      } catch (e) {
        action = Actions.receiveLevelLabelsEditError();
        defaultErrorToast(
          i18n.t('errors.error-sorry-an-error-occurred-during.get-level-label')
        )(e);
        onError?.(e);
      }

      dispatch(action);
    },

  getLevelLabelsByTestId:
    ({ id, isCustomTest, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestLevelLabelsFields());

      try {
        const response = await request({
          method: 'GET',
          url: LEVEL_LABELS_GET_MARKS_BY_TEST_URL,
          params: {
            test_id: isCustomTest ? undefined : id,
            custom_test_id: isCustomTest ? id : undefined,
          },
        });

        action = Actions.receiveLevelLabelsFieldsList(response.data.content);
        onSuccess?.(response.data.content);
      } catch (e) {
        action = Actions.receiveLevelLabelsFieldsError();
        defaultErrorToast(
          i18n.t('errors.error-sorry-an-error-occurred-during.get-level-label')
        )(e);
        onError?.(e);
      }

      dispatch(action);
    },

  createLevelLabels:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLevelLabels());

      const isCustomTest = Boolean(data?.isCustomTest);
      delete data.isCustomTest;

      try {
        const formData = formDataFromObj({
          ...data,
          test_id: isCustomTest ? undefined : data?.test_id,
          custom_test_id: isCustomTest ? data?.test_id : undefined,
        });

        const response = await request({
          method: 'POST',
          url: LEVEL_LABELS_URL,
          data: formData,
        });

        dispatch(Actions.setLoading(false));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveLevelLabelsError());
        onError(e);
      }
    },

  updateLevelLabels:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLevelLabels());

      const isCustomTest = Boolean(data?.isCustomTest);
      delete data.isCustomTest;

      try {
        const formData = formDataFromObj({
          ...data,
          test_id: isCustomTest ? undefined : data?.test_id,
          custom_test_id: isCustomTest ? data?.test_id : undefined,
        });

        const response = await request({
          method: 'PUT',
          url: `${LEVEL_LABELS_URL}/${data?.id}`,
          data: formData,
        });

        dispatch(Actions.setLoading(false));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveLevelLabelsError());
        onError(e);
      }
    },

  deleteLevelLabels:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLevelLabels());

      try {
        const response = await request({
          method: 'DELETE',
          url: `${LEVEL_LABELS_URL}/${id}`,
        });

        dispatch(Actions.setLoading(false));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveLevelLabelsError());
        onError(e);
      }
    },

  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelsList,
      Actions.receiveWhitelabelsError
    ),

  fetchTestsList: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestTests());

    try {
      const [baseTestsResponse, customTestsResponse] = await Promise.all([
        request({
          method: 'GET',
          url: BASE_TESTS_URL,
        }),
        request({
          method: 'GET',
          url: CUSTOM_TESTS_URL,
        }),
      ]);

      action = Actions.receiveTestsList({
        tests: baseTestsResponse?.data?.content?.tests,
        customTests: customTestsResponse?.data?.content?.custom_tests,
      });
    } catch (e) {
      action = Actions.receiveTestsError();
      defaultErrorToast(
        i18n.t('errors.error-sorry-an-error-occurred-during.get-test')
      )(e);
    }

    dispatch(action);
  },
};

const { reducer } = levelLabelsSlice;

export { reducer, Actions, Async, Selectors };
