import { useDispatch, useSelector } from 'react-redux';
import { Async, Selectors } from '../InstitutionsFilters/InstitutionsSlice';
import { useEffect } from 'react';

export const useInstitutionFiltersGroups = ({ initialFetch } = {}) => {
  const dispatch = useDispatch();
  const { data: groups, loading, error } = useSelector(Selectors.groups);

  const fetchGroupsForSelect = () => {
    dispatch(Async.fetchGroupsListForSelect());
  };

  useEffect(() => {
    if (initialFetch) {
      fetchGroupsForSelect();
    }
  }, [initialFetch]);

  return {
    groups: groups || [],
    loading,
    error,
    fetchGroupsForSelect,
  };
};
