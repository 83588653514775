export const normalizeTestTag = (testTag) => {
  if (!testTag) return '-';

  const multipleConsecutiveUnderscoresRegex = /[_]+/g;

  return testTag
    .trim()
    .replace(multipleConsecutiveUnderscoresRegex, '_')
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const denormalizeTestName = (testName) => {
  if (!testName) return '';

  const multipleConsecutiveSpacesRegex = /\s+/g;

  return testName
    .trim()
    .replace(multipleConsecutiveSpacesRegex, ' ')
    .split(' ')
    .map((word) => word.toUpperCase())
    .join('_');
};
