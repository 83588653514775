import PropTypes from 'prop-types';
import i18n from 'i18next';

import {
  ItemsColumn,
  ItemLabel,
  ItemLink,
  ItemToggle,
  CategorySelect,
} from 'modules/Core/Common';
import { AiEvaluationButton } from 'modules/Submissions/Common';
import { AnswersHeader } from 'modules/Submissions/Common/Answers';
import { I18N_PREFIX } from 'modules/Core/Common/HeaderInfo/HeaderInfo.utils';

export function SubmissionAnswersHeader({
  loading,
  testType,
  testCategory,
  student,
  id,
  date,
  categories,
  selectedCategory,
  onChangeCategory,
  deviceInfo,
  scores,
  canSendToAnotherEvaluator,
  onSendToAnotherEvaluator,
  certificateAvailable,
  scoreAvailable,
  scoreControl,
  onChangeCertificateAvailable,
  onChangeScoreAvailable,
  isChangingCertificateAvailable,
  isChangingScoreAvailable,
  onAiEvaluation,
  isCustomTest,
}) {
  const shouldShowLinksColumn = !!canSendToAnotherEvaluator;

  return (
    <AnswersHeader
      loading={loading}
      student={student}
      id={id}
      date={date}
      testType={testType}
      testCategory={testCategory}
      deviceInfo={deviceInfo}
      scores={scores}
      categories={categories}
      selectedCategory={selectedCategory}
      onChangeCategory={onChangeCategory}
      isCustomTest={isCustomTest}
    >
      <ItemsColumn>
        <ItemLabel title={i18n.t(`${I18N_PREFIX}.test-type`)}>
          {testType}
        </ItemLabel>
        {testCategory === 'full' && <CategorySelect />}
      </ItemsColumn>
      {shouldShowLinksColumn && (
        <ItemsColumn>
          {!!canSendToAnotherEvaluator && (
            <ItemLink
              icon="evaluate"
              title={i18n.t(`${I18N_PREFIX}.send-to-another-evaluator`)}
              onClick={onSendToAnotherEvaluator}
            />
          )}
        </ItemsColumn>
      )}
      {!!onChangeCertificateAvailable && (
        <ItemsColumn>
          <ItemToggle
            title={i18n.t(`${I18N_PREFIX}.certificate-available`)}
            active={certificateAvailable}
            loading={isChangingCertificateAvailable}
            onChange={({ target }) =>
              onChangeCertificateAvailable(target.checked)
            }
          />
          {!!onAiEvaluation && (
            <AiEvaluationButton onConfirm={onAiEvaluation} />
          )}
        </ItemsColumn>
      )}
      {!!scoreControl && !!onChangeScoreAvailable && (
        <ItemsColumn>
          <ItemToggle
            title={i18n.t(`${I18N_PREFIX}.score-available`)}
            active={scoreAvailable}
            loading={isChangingScoreAvailable}
            onChange={({ target }) => onChangeScoreAvailable(target.checked)}
          />
        </ItemsColumn>
      )}
    </AnswersHeader>
  );
}

SubmissionAnswersHeader.propTypes = {
  loading: PropTypes.bool,
  testType: PropTypes.string,
  testCategory: PropTypes.string,
  student: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  categories: PropTypes.array,
  selectedCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeCategory: PropTypes.func,
  deviceInfo: PropTypes.object,
  scores: PropTypes.array,
  certificateAvailable: PropTypes.bool,
  scoreAvailable: PropTypes.bool,
  scoreControl: PropTypes.bool,
  onChangeCertificateAvailable: PropTypes.func,
  onChangeScoreAvailable: PropTypes.func,
  onSendToAnotherEvaluator: PropTypes.func,
  canSendToAnotherEvaluator: PropTypes.bool,
  isChangingCertificateAvailable: PropTypes.bool,
  isChangingScoreAvailable: PropTypes.bool,
  onAiEvaluation: PropTypes.func,
  isCustomTest: PropTypes.bool,
};
