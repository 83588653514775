import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PLACEMENT_TEST_NAME } from 'constants';
import { denormalizeTestName } from 'modules/Utils/normalizeTestTag';
import i18n from 'i18next';

const TEST_CATEGORY_LABELS = {
  [PLACEMENT_TEST_NAME]: {
    reading: 'common-words.use-of-english',
  },
};

export function TestCategoryLabel({ testName, category }) {
  const categoryLabel = useMemo(() => {
    if (!category) return '';

    const testTag = denormalizeTestName(testName);
    const normalizedCategory = category.toLowerCase();

    if (testTag && TEST_CATEGORY_LABELS[testTag]) {
      const testSpecificLabel =
        TEST_CATEGORY_LABELS[testTag][normalizedCategory];
      if (testSpecificLabel) {
        return i18n.t(testSpecificLabel);
      }
    }

    return category;
  }, [testName, category]);

  return <>{categoryLabel}</>;
}

TestCategoryLabel.defaultProps = {
  testName: '',
  category: '',
};

TestCategoryLabel.propTypes = {
  testName: PropTypes.string,
  category: PropTypes.string,
};
