import i18n from 'i18next';

export const getCertificatesOptions = () => [
  {
    id: 'ALL',
    label: i18n.t('common-words.all'),
    type: 'certification',
    filterName: 'certificate_status',
  },
  {
    id: 'CERTIFICATE_AVAILABLE',
    label: i18n.t('commons.filter.certificate-available'),
    type: 'certification',
    filterName: 'certificate_status',
  },
  {
    id: 'CERTIFICATE_NOT_AVAILABLE',
    label: i18n.t('commons.filter.certificate-not-available'),
    type: 'certification',
    filterName: 'certificate_status',
  },
];
