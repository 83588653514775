import { configureStore } from '@reduxjs/toolkit';

import { reducer as appReducer } from 'modules/Core/AppSlice';
import { reducer as authReducer } from 'modules/Auth/AuthSlice';
import { reducer as licensesReducer } from 'modules/Licenses/LicensesSlice';
import { reducer as studentReducer } from 'modules/Users/Students/StudentSlice';
import { reducer as teachersReducer } from 'modules/Users/Teachers/TeachersSlice';
import { reducer as superAdminReducer } from 'modules/Users/SuperAdmin/SuperAdminSlice';
import { reducer as groupAdminReducer } from 'modules/Users/GroupAdmin/GroupAdminSlice';
import { reducer as evaluatorsReducer } from 'modules/Users/Evaluators/EvaluatorsSlice';
import { reducer as creatorsReducer } from 'modules/Users/Creators/CreatorSlice';
import { reducer as unitAdminReducer } from 'modules/Users/UnitAdmin/UnitAdminSlice';
import { reducer as schoolAdminReducer } from 'modules/Users/SchoolAdmin/SchoolAdminSlice';
import { reducer as studentLicenseReducer } from 'modules/Users/Students/StudentLicenseSlice';
import { reducer as antifraudPluginReducer } from 'modules/Antifraud/Plugin/AntifraudPluginSlice';
import { reducer as overallScoringReducer } from 'modules/OverallScoring/OverallScoringSlice';
import { reducer as practiceResultsReducer } from 'modules/Practice/PracticeResults/PracticeResultsSlice';
import { reducer as simulationResultsReducer } from 'modules/Simulation/SimulationResults/SimulationResultsSlice';
import { reducer as SubmissionsReducer } from 'modules/Submissions/SubmissionsSlice';
import { reducer as GroupReducer } from 'modules/Institutions/Groups/GroupsSlice';
import { reducer as UnitReducer } from 'modules/Institutions/Units/UnitsSlice';
import { reducer as SchoolReducer } from 'modules/Institutions/Schools/SchoolsSlice';
import { reducer as ClassReducer } from 'modules/Institutions/Classes/ClassesSlice';
import { reducer as InstitutionsFiltersReducer } from 'modules/Institutions/InstitutionsFilters/InstitutionsSlice';
import { reducer as AnswerSheetReducer } from 'modules/Simulation/AnswerSheet/AnswerSheetSlice';
import { reducer as BaseTestsReducer } from 'modules/BaseTests/BaseTestsSlice';
import { reducer as CustomTestsReducer } from 'modules/CustomTests/CustomTestsSlice';
import { reducer as TimezonesReducer } from 'modules/Timezones/TimezonesSlice';
import { reducer as LanguagesReducer } from 'modules/Languages/LanguagesSlice';
import { reducer as SimulationsBaseReducer } from 'modules/Simulation/SimulationsBase/SimulationsBaseSlice';
import { reducer as SimulationsCustomReducer } from 'modules/Simulation/SimulationsCustom/SimulationsCustomSlice';
import { reducer as AntifraudReducer } from 'modules/Antifraud/AntifraudSlice';
import { reducer as TestsReducer } from 'modules/Tests/TestsBase/TestsSlice';
import { reducer as testsCustomReducer } from 'modules/Tests/TestsCustom/TestsCustomSlice';
import { reducer as countryPermissionsReducer } from 'modules/CountryPermissions/CountryPermissionsSlice';
import { reducer as proctoringSettingsOriginReducer } from 'modules/ProctoringSettings/ProctoringSettingsOrigin/ProctoringSettingsOriginSlice';
import { reducer as proctoringSettingsListReducer } from 'modules/ProctoringSettings/ProctoringSettingsList/ProctoringSettingsListSlice';
import { reducer as proctoringSettingsEditReducer } from 'modules/ProctoringSettings/ProctoringSettingsEdit/ProctoringSettingsEditSlice';
import { reducer as purchasesReducer } from 'modules/Purchases/PurchasesSlice';
import { reducer as notificationsReducer } from 'modules/Settings/Notifications/NotificationsSlice';
import { reducer as externalSoftwaresReducer } from 'modules/Softwares/ExternalSoftwares/ExternalSoftwaresSlice';
import { reducer as versionsReducer } from 'modules/Softwares/Versions/VersionsSlice';
import { reducer as integrationsReducer } from 'modules/Softwares/Integrations/IntegrationsSlice';
import { reducer as apiUsersReducer } from 'modules/Softwares/APIUsers/APIUsersSlice';
import { reducer as dashboardReducer } from 'modules/Dashboard/DashboardSlice';
import { reducer as widgetsReducer } from 'modules/Dashboard/Widgets/WidgetsSlice';
import { reducer as apiLogReducer } from 'modules/Settings/ApiLog/ApiLogSlice';
import { reducer as whitelabelsReducer } from 'modules/Whitelabel/WhitelabelSlice';
import { reducer as licenseHistoryReducer } from 'modules/LicenseHistory/LicenseHistorySlice';
import { reducer as externalApplicationsReducer } from 'modules/ExternalApplications/ExternalApplicationsSlice';
import { reducer as liveInterviewReducer } from 'modules/EdusynchLive/LiveInterview/LiveInterviewSlice';
import { reducer as liveTestsReducer } from 'modules/EdusynchLive/LiveTests/LiveTestsSlice';
import { reducer as liveCodesReducer } from 'modules/EdusynchLive/LiveTests/LiveCodes/LiveCodesSlice';
import { reducer as AIEvaluatorSlice } from 'modules/Settings/AIEvaluator/AIEvaluatorSlice';
import { reducer as FeatureFlagsSlice } from 'modules/Settings/FeatureFlags/FeatureFlagsSlice';
import { reducer as SubscriptionsSlice } from 'modules/Subscriptions/SubscriptionsSlice';
import { reducer as DiscountsSlice } from 'modules/Discounts/DiscountsSlice';
import { reducer as PlansSubscriptionSlice } from 'modules/Subscription/SubscriptionSlice';
import { reducer as MaintenanceSlice } from 'modules/Maintenance/MaintenanceSlice';
import { reducer as SettingsSlice } from 'modules/Settings/SettingsSlice';
import { reducer as CancelFeedbackSlice } from 'modules/CancelFeedback/CancelFeedbackSlice';
import { reducer as ContentTypesSlice } from 'modules/ContentTypes/ContentTypesSlice';
import { reducer as LevelLabelsSlice } from 'modules/Tests/LevelLabels/LevelLabelsSlice';

const config = {
  reducer: {
    app: appReducer,
    auth: authReducer,
    student: studentReducer,
    license: licensesReducer,
    teachers: teachersReducer,
    unitAdmin: unitAdminReducer,
    evaluator: evaluatorsReducer,
    creator: creatorsReducer,
    superAdmin: superAdminReducer,
    groupAdmin: groupAdminReducer,
    schoolAdmin: schoolAdminReducer,
    studentLicense: studentLicenseReducer,
    antifraudPlugin: antifraudPluginReducer,
    overallScoring: overallScoringReducer,
    practiceResults: practiceResultsReducer,
    simulationResults: simulationResultsReducer,
    group: GroupReducer,
    unit: UnitReducer,
    school: SchoolReducer,
    class: ClassReducer,
    institutionsFilters: InstitutionsFiltersReducer,
    submissions: SubmissionsReducer,
    answerSheet: AnswerSheetReducer,
    baseTests: BaseTestsReducer,
    customTests: CustomTestsReducer,
    timezones: TimezonesReducer,
    languages: LanguagesReducer,
    simulationsBase: SimulationsBaseReducer,
    simulationsCustom: SimulationsCustomReducer,
    antifraud: AntifraudReducer,
    tests: TestsReducer,
    testsCustom: testsCustomReducer,
    countryPermissions: countryPermissionsReducer,
    notifications: notificationsReducer,
    proctoringSettingsOrigin: proctoringSettingsOriginReducer,
    proctoringSettingsList: proctoringSettingsListReducer,
    proctoringSettingsEdit: proctoringSettingsEditReducer,
    purchases: purchasesReducer,
    externalSoftwares: externalSoftwaresReducer,
    versions: versionsReducer,
    integrations: integrationsReducer,
    apiUsers: apiUsersReducer,
    dashboard: dashboardReducer,
    widgets: widgetsReducer,
    whitelabels: whitelabelsReducer,
    apiLog: apiLogReducer,
    licenseHistory: licenseHistoryReducer,
    externalApplications: externalApplicationsReducer,
    liveInterview: liveInterviewReducer,
    liveTests: liveTestsReducer,
    liveCodes: liveCodesReducer,
    aiEvaluator: AIEvaluatorSlice,
    features: FeatureFlagsSlice,
    subscriptions: SubscriptionsSlice,
    discounts: DiscountsSlice,
    maintenance: MaintenanceSlice,
    settings: SettingsSlice,
    subscription: PlansSubscriptionSlice,
    cancelFeedback: CancelFeedbackSlice,
    contentTypes: ContentTypesSlice,
    levelLabels: LevelLabelsSlice,
  },
};

export default configureStore(config);
