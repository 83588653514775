import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Selectors,
  Async,
  Actions,
} from 'modules/Dashboard/Widgets/WidgetsSlice';

import { REGISTERED_USERS } from 'modules/Dashboard/Widgets/Common/WidgetByType/Widgets.types';

export const useRegisteredUsers = () => {
  const { registeredUsers } = useSelector(Selectors.fetchListData);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(Actions.cancelRequests());
  }, []);

  const fetchRegisteredUsers = (intervalOption = '7') => {
    dispatch(
      Async.fetchWidget({
        widget_type: REGISTERED_USERS,
        filters: {
          days_type: +intervalOption,
        },
      })
    );
  };

  return {
    fetchRegisteredUsers,
    registeredUsers,
  };
};
