import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';

import {
  GROUPS_SELECT_URL,
  UNITS_BY_GROUP_URL,
  SCHOOLS_BY_UNIT_URL,
} from 'modules/Api/Routes';

let cancelToken;

const initialState = {
  groups: {
    data: [],
    loading: false,
    error: null,
  },
  units: {
    data: [],
    loading: false,
    error: null,
  },
  schools: {
    data: [],
    loading: false,
    error: null,
  },
};

const institutionsSlice = createSlice({
  name: 'institutionsFilters',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),

    // Groups
    requestGroups: (state) => {
      state.groups.loading = true;
      state.groups.error = null;
    },
    receiveGroupsList: (state, action) => {
      state.groups.data = action.payload.groups || [];
      state.groups.loading = false;
    },
    receiveGroupsError: (state, action) => {
      state.groups.loading = false;
      state.groups.error = action.payload;
    },

    // Units
    requestUnits: (state) => {
      state.units.loading = true;
      state.units.error = null;
    },
    receiveUnitsList: (state, action) => {
      state.units.data = action.payload || [];
      state.units.loading = false;
    },
    receiveUnitsError: (state, action) => {
      state.units.loading = false;
      state.units.error = action.payload;
    },

    // Schools
    requestSchools: (state) => {
      state.schools.loading = true;
      state.schools.error = null;
    },
    receiveSchoolsList: (state, action) => {
      state.schools.data = action.payload.schools || [];
      state.schools.loading = false;
    },
    receiveSchoolsError: (state, action) => {
      state.schools.loading = false;
      state.schools.error = action.payload;
    },
  },
});

const Actions = institutionsSlice.actions;

const Selectors = {
  groups: (state) => state.institutionsFilters.groups,
  units: (state) => state.institutionsFilters.units,
  schools: (state) => state.institutionsFilters.schools,
};

const Async = {
  fetchGroupsListForSelect: () => async (dispatch) => {
    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    dispatch(Actions.requestGroups());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: GROUPS_SELECT_URL,
      });

      dispatch(Actions.receiveGroupsList(response.data.content));
    } catch (e) {
      if (!isCancel(e)) {
        dispatch(Actions.receiveGroupsError(e.message));
      }
    }
  },

  fetchUnitsListByGroup:
    ({ groupId }) =>
    async (dispatch) => {
      if (!groupId) return;

      dispatch(Actions.requestUnits());

      try {
        const response = await request({
          method: 'GET',
          url: `${UNITS_BY_GROUP_URL}?group_id=${groupId}`,
        });

        dispatch(Actions.receiveUnitsList(response.data.content));
      } catch (e) {
        dispatch(Actions.receiveUnitsError(e.message));
      }
    },

  fetchSchoolsListByUnitId:
    ({ unitId }) =>
    async (dispatch) => {
      if (!unitId) return;

      dispatch(Actions.requestSchools());

      try {
        const response = await request({
          method: 'GET',
          url: `${SCHOOLS_BY_UNIT_URL}?unit_id=${unitId}`,
        });

        dispatch(Actions.receiveSchoolsList(response.data.content));
      } catch (e) {
        dispatch(Actions.receiveSchoolsError(e.message));
      }
    },
};

const reducer = institutionsSlice.reducer;

export { reducer, Actions, Async, Selectors };
