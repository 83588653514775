import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  isCancel,
  generateCancelToken,
  ejectCancelInterceptor,
  cancelRequests,
} from 'modules/Api/HttpClient';
import camelCase from 'camelcase';
import {
  DASHBOARD_WIDGET,
  SETTINGS_DASHBOARD,
  DASHBOARD_EPROCTORING_EVENTS_ANALYTICS_URL,
  DASHBOARD_EPROCTORING_SESSIONS_COUNT_URL,
  DASHBOARD_COMPLETION_RATE_URL,
  STUDENTS_URL,
} from 'modules/Api/Routes';
import { Toast } from 'modules/Core/Common';
import { defaultErrorToast } from 'modules/Utils';
import { formDataFromObj } from 'modules/Api/RequestData';
import { persistUser } from 'modules/Auth/Services';
import { Actions as AuthActions } from 'modules/Auth/AuthSlice';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    activeWidgets: [],
  },
  activeUsers: {
    loading: false,
    error: null,
    data: {},
  },
  registeredUsers: {
    loading: false,
    error: null,
    data: {},
  },
  eproctoringEventsAnalyticsBaseTests: {
    loading: false,
    error: null,
    data: {},
  },
  eproctoringEventsAnalyticsCustomTests: {
    loading: false,
    error: null,
    data: {},
  },
  eproctoringEventsAnalyticsPlugin: {
    loading: false,
    error: null,
    data: {},
  },
  eproctoringSessionsCount: {
    loading: false,
    error: null,
    data: {},
  },
  completionRateBaseTests: {
    loading: false,
    error: null,
    data: {},
  },
  completionRateCustomTests: {
    loading: false,
    error: null,
    data: {},
  },
  completionRatePlugin: {
    loading: false,
    error: null,
    data: {},
  },
  users: {
    loading: false,
    error: null,
    data: [],
  },
  unsavedChanges: false,
};

const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    requestWidgets: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestWidget: (state, action) => {
      state[action.payload.widget_type].loading = true;
      state[action.payload.widget_type].error = null;
    },
    requestUsers: (state) => {
      state.users.loading = true;
      state.users.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload;
    },
    setActiveWidgets: (state, action) => {
      state.data.activeWidgets = action.payload || [];
    },
    addActiveWidget: (state, action) => {
      state.data.activeWidgets.push(action.payload);
    },
    removeActiveWidget: (state, action) => {
      state.data.activeWidgets = state.data.activeWidgets.filter(
        (widget) => widget.id !== action.payload
      );
    },
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    receiveWidgetsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        widgets: action.payload.widgets,
        total: action.payload.total_items,
      };
    },
    receiveWidgetData: (state, action) => {
      state[action.payload.widget_type].loading = false;
      state[action.payload.widget_type].data = {
        ...action.payload.data,
      };
    },
    clearWidgetsList: (state) => {
      state.loading = false;
      state.data = {
        widgets: [],
      };
    },
    receiveWidgetsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveWidgetError: (state, action) => {
      state[action.payload.widget_type].loading = false;
      state[action.payload.widget_type].error = action.payload.error;
    },
    saveWidgetSettings: (state, action) => {
      const widgetSettings = action.payload;

      state.data.activeWidgets = state.data.activeWidgets.map((widget) => {
        if (widget.id === widgetSettings.widgetId) {
          return {
            ...widget,
            settings: {
              ...widget.settings,
              size: widgetSettings.size,
            },
          };
        }

        return widget;
      });
    },
    receiveUsersList: (state, action) => {
      state.users.loading = false;
      state.users.data = action.payload.users || [];
    },
    receiveUsersError: (state, action) => {
      state.users.loading = false;
      state.users.error = action.payload;
    },
  },
});

const Actions = widgetsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.widgets,
  widgetsLoading: ({ widgets: { loading } }) => ({ loading }),
};

const Async = {
  fetchWidget:
    ({ widget_type, filters }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestWidget({ widget_type: camelCase(widget_type) }));

      try {
        const response = await request({
          method: 'GET',
          url: DASHBOARD_WIDGET,
          params: {
            type: widget_type,
            filters,
          },
        });

        action = Actions.receiveWidgetData({
          widget_type: camelCase(widget_type),
          data: response.data.content,
        });
      } catch (e) {
        action = Actions.receiveWidgetError({
          widget_type: camelCase(widget_type),
          error: e.message,
        });
      }

      dispatch(action);
    },

  fetchEProctoringEventsAnalytics:
    ({ widget_type, filters }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestWidget({ widget_type: camelCase(widget_type) }));

      const testType = widget_type?.toLowerCase().includes('base')
        ? 'base'
        : widget_type?.toLowerCase().includes('custom')
        ? 'custom'
        : 'plugin';

      try {
        const response = await request({
          method: 'GET',
          url: DASHBOARD_EPROCTORING_EVENTS_ANALYTICS_URL,
          params: {
            test_type: testType,
            ...filters,
          },
        });

        action = Actions.receiveWidgetData({
          widget_type: camelCase(widget_type),
          data: { events: response.data.content },
        });
      } catch (e) {
        action = Actions.receiveWidgetError({
          widget_type: camelCase(widget_type),
          error: e.message,
        });
      }

      dispatch(action);
    },

  fetchEProctoringSessionsCount:
    ({ widget_type, filters }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestWidget({ widget_type: camelCase(widget_type) }));

      try {
        const response = await request({
          method: 'GET',
          url: DASHBOARD_EPROCTORING_SESSIONS_COUNT_URL,
          params: {
            test_type: 'plugin',
            ...filters,
          },
        });

        action = Actions.receiveWidgetData({
          widget_type: camelCase(widget_type),
          data: { ...response.data.content },
        });
      } catch (e) {
        action = Actions.receiveWidgetError({
          widget_type: camelCase(widget_type),
          error: e.message,
        });
      }

      dispatch(action);
    },

  fetchCompletionRate:
    ({ widget_type, filters }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestWidget({ widget_type: camelCase(widget_type) }));

      const testType = widget_type?.toLowerCase().includes('base')
        ? 'base'
        : widget_type?.toLowerCase().includes('custom')
        ? 'custom'
        : 'plugin';

      try {
        const response = await request({
          method: 'GET',
          url: DASHBOARD_COMPLETION_RATE_URL,
          params: {
            test_type: testType,
            ...filters,
          },
        });

        action = Actions.receiveWidgetData({
          widget_type: camelCase(widget_type),
          data: { tests: response.data.content },
        });
      } catch (e) {
        action = Actions.receiveWidgetError({
          widget_type: camelCase(widget_type),
          error: e.message,
        });
      }

      dispatch(action);
    },

  saveWidgets: () => async (dispatch, getState) => {
    dispatch(Actions.setLoading(true));

    const {
      widgets: {
        data: { activeWidgets },
      },
    } = getState();

    const data = formDataFromObj({
      dashboard: {
        widgets: activeWidgets,
      },
    });

    try {
      const response = await request({
        method: 'PATCH',
        url: SETTINGS_DASHBOARD,
        data,
      });

      const updatedUser = response?.data?.content;
      persistUser(updatedUser);
      dispatch(AuthActions.updateUserData({ user: updatedUser }));
      dispatch(Actions.setLoading(false));
      dispatch(Actions.setUnsavedChanges(false));
      Toast('Dashboard saved.', 'success');
    } catch (e) {
      dispatch(Actions.setLoading(false));
      defaultErrorToast('Sorry, an error occurred during save dashboard!')(e);
    }
  },

  fetchStudentsList:
    ({ search }) =>
    async (dispatch) => {
      ejectCancelInterceptor();
      cancelToken?.cancel();
      cancelToken = generateCancelToken();

      let action;

      dispatch(Actions.requestUsers());

      try {
        const response = await request({
          cancelToken: cancelToken.token,
          method: 'GET',
          url: STUDENTS_URL,
          params: {
            search,
            page: 0,
            paginates_per: 100,
            start_date: '',
            end_date: '',
          },
        });

        action = Actions.receiveUsersList({
          users: response.data.content.students,
        });
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveUsersError(e.message);
        }
      }

      action && dispatch(action);
    },
};

const reducer = widgetsSlice.reducer;

export { reducer, Actions, Async, Selectors };
