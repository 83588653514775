import { useDispatch, useSelector } from 'react-redux';

import { Selectors, Async } from 'modules/Submissions/SubmissionsSlice';

export const useChangeScoreAvailable = () => {
  const dispatch = useDispatch();
  const state = useSelector(Selectors.fetchListData);

  const handleChangeScoreAvailable = ({ exerciseId, onSuccess }) => {
    const action = Async.changeScoreAvailable({
      exerciseId,
      onSuccess,
    });

    dispatch(action);
  };

  return {
    isChangingScoreAvailable: state.isChangingScoreAvailable,
    handleChangeScoreAvailable,
  };
};
