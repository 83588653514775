import { useEffect, useCallback, useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useURLSearchQuery } from 'modules/Core/Hooks';

import { useQuery } from 'modules/Core/Hooks';

import {
  Selectors,
  Actions,
  Async,
} from 'modules/Submissions/SubmissionsSlice';
import { PLACEMENT_NAME, CATEGORY_READING } from 'constants';
import i18n from 'i18next';

export const useSubmissionAnswers = () => {
  const state = useSelector(Selectors.fetchListData);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchQuery = useQuery();
  const pageQuery = Number(searchQuery.get('page'));
  const categoryTagQuery = searchQuery.get('category');
  const categoryIdQuery = searchQuery.get('category_id');
  const { exerciseId, testCategory } = useParams();
  const { queryObject, changeParams } = useURLSearchQuery();
  const isCustomTest = location.pathname.includes('custom-tests');
  const isPractice = location.pathname.includes('practice');

  useEffect(() => {
    if (categoryTagQuery || categoryIdQuery)
      dispatch(
        Actions.changeSubmissionAnswersCategory({
          tag: categoryTagQuery,
          id: categoryIdQuery,
        })
      );

    if (pageQuery) dispatch(Actions.changeSubmissionAnswersPage(pageQuery));
    if (!state?.loading)
      fetchSubmissionAnswers({ id: exerciseId, isCustomTest });
  }, [
    state.answers.page,
    state.answers.perPage,
    state.answers.categoryId,
    state.answers.categoryTag,
    state.answers.sort.sortType,
    state.answers.sort.sortBy,
  ]);

  useEffect(() => {
    return () => dispatch(Actions.cleanState());
  }, [location.pathname]);

  const clearError = () => dispatch(Actions.clearError());

  const fetchSubmissionAnswers = (params) => {
    if (isPractice)
      return dispatch(Async.fetchPracticeSubmissionAnswers(params));
    dispatch(Async.fetchSubmissionAnswers(params));
  };

  const fetchSubmissionAnswer = (answerId) => {
    dispatch(Async.fetchSubmissionAnswer({ id: answerId }));
  };

  const fetchSubmissionPracticeAnswer = (answerId) => {
    dispatch(Async.fetchSubmissionPracticeAnswer({ id: answerId }));
  };

  const onNextPage = (page) => {
    changeParams({ ...queryObject, page });
    return dispatch(Actions.changeSubmissionAnswersPage(page));
  };

  const onChangePerPage = useCallback(
    (value) => {
      changeParams({ ...queryObject, page: 1 });
      dispatch(Actions.changeSubmissionAnswersPerPage(value));
    },
    [state.answers.perPage]
  );

  const categories = useMemo(
    () =>
      state?.categories.map((category) => {
        const isPlacement = category?.test_name
          ?.toUpperCase()
          ?.includes(PLACEMENT_NAME);
        const isPlacementReadingCategory =
          isPlacement && category?.tag?.toLowerCase() === CATEGORY_READING;
        return {
          id: category.id,
          name: isPlacementReadingCategory
            ? i18n.t('common-words.use-of-english')
            : category.name,
        };
      }),
    [state.categories]
  );

  const onCategorySelect = (categoryId) => {
    const category = state?.categories?.find(
      (category) => category?.id === categoryId
    );

    const urlSufix =
      category?.id !== 'all'
        ? `?category=${category?.tag}&category_id=${category?.id}`
        : '';
    history.push(`${location.pathname}${urlSufix}`);

    dispatch(Actions.changeSubmissionAnswersCategory(category));
  };

  const onChangeSort = (sortBy) => dispatch(Actions.changeAnswersSort(sortBy));

  return {
    exerciseId,
    testCategory,
    state,
    categories,
    fetchSubmissionAnswers,
    fetchSubmissionAnswer,
    fetchSubmissionPracticeAnswer,
    onNextPage,
    onChangePerPage,
    onCategorySelect,
    clearError,
    onChangeSort,
  };
};
