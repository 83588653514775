import { useDispatch, useSelector } from 'react-redux';
import { Async, Selectors } from '../InstitutionsFilters/InstitutionsSlice';

export const useInstitutionFiltersUnits = () => {
  const dispatch = useDispatch();
  const { data: units, loading, error } = useSelector(Selectors.units);

  const fetchUnitsByGroup = (groupId) => {
    if (groupId) {
      dispatch(Async.fetchUnitsListByGroup({ groupId }));
    }
  };

  return {
    units: units || [],
    loading,
    error,
    fetchUnitsByGroup,
  };
};
